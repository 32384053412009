import { useContext, createContext, useCallback, useMemo, useReducer } from 'react';
import { ErrorScreen, TimeoutScreen } from 'shared-fe-components/src/common/StatusScreens';
import { SigningContextProviderProps, SigningContextValues, State, Action, ErrorProps } from './SigningContext.types';
import { usePersistentStorage } from 'shared-fe-components/src/hooks';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import * as api from 'shared-fe-components/src/api/index';
import './SigningContext.scss';

const signingInitialState: State = {
  overlay: null,
  selectedProvider: null,
  errorProps: null,
  showPrivacyPolicy: false,
};

const signingReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'PROVIDER_SELECTION':
      return {
        ...signingInitialState,
      };
    case 'PROVIDER':
      return {
        ...state,
        selectedProvider: action.selectedProvider,
      };
    case 'ERROR':
      return {
        ...state,
        overlay: 'error',
        errorProps: action.errorProps,
      };
    case 'TIMEOUT':
      return {
        ...state,
        overlay: 'timeout',
      };
    case 'HIDE_OVERLAY':
      return {
        ...state,
        overlay: null,
        errorProps: null,
      };
    case 'SIGNATURE_LOCKED':
      return {
        ...state,
        selectedProvider: '_dummy:SignatureLocked',
      };
    case 'SHOW_PRIVACY_POLICY':
      return {
        ...state,
        showPrivacyPolicy: true,
      };
    case 'HIDE_PRIVACY_POLICY':
      return {
        ...state,
        showPrivacyPolicy: false,
      };
  }
};

const SigningContext = createContext<SigningContextValues | null>(null);

export const SigningContextProvider = ({
  sessionId,
  signatureId,
  file,
  otpCode,
  xadesFormat,
  recipientPhoneNumber,
  goToIntro,
  goToSuccess,
  initialProvider,
  setOtpCode,
  children,
}: SigningContextProviderProps) => {
  const [signingState, signingDispatch] = useReducer(signingReducer, {
    ...signingInitialState,
    selectedProvider: initialProvider,
  });

  // FIXME: cancel confirmation
  const onCancel = () => {
    goToIntro();
    goToProviderSelection();
  };

  const [privacyPolicyAcceptance, setPrivacyPolicyAcceptance] = usePersistentStorage('privacy-policy-acceptance');
  const isPrivacyPolicyVisible = useMemo(() => {
    if (!privacyPolicyAcceptance && signingState.selectedProvider !== null) {
      // user hasn't accepted privacy policy and has to do it to proceed
      return true;
    }
    if (signingState.showPrivacyPolicy) {
      // user wants to read it for some reason
      return true;
    }

    return false;
  }, [privacyPolicyAcceptance, signingState.selectedProvider, signingState.showPrivacyPolicy]);

  const acceptPrivacyPolicy = useCallback(() => {
    setPrivacyPolicyAcceptance(true);
    signingDispatch({ type: 'HIDE_PRIVACY_POLICY' });
  }, [setPrivacyPolicyAcceptance]);

  const closePrivacyPolicy = useCallback(() => {
    if (!privacyPolicyAcceptance) {
      onCancel();
    } else {
      signingDispatch({ type: 'HIDE_PRIVACY_POLICY' });
    }
  }, [privacyPolicyAcceptance, onCancel]);

  const showPrivacyPolicy = () => signingDispatch({ type: 'SHOW_PRIVACY_POLICY' });

  const overlay = useMemo(() => signingState.overlay, [signingState.overlay]);
  const selectedProvider = useMemo<string>(
    () => signingState.selectedProvider as string,
    [signingState.selectedProvider]
  );
  const setSelectedProvider = (selectedProvider: string) => signingDispatch({ type: 'PROVIDER', selectedProvider });
  const goToProviderSelection = () => signingDispatch({ type: 'PROVIDER_SELECTION' });
  const goToSignatureLockedScreen = () => signingDispatch({ type: 'SIGNATURE_LOCKED' });
  const onError = (errorProps: ErrorProps) => signingDispatch({ type: 'ERROR', errorProps });
  const onTimeout = () => signingDispatch({ type: 'TIMEOUT' });
  const hideOverlay = () => signingDispatch({ type: 'HIDE_OVERLAY' });

  const sign = useCallback(
    async ({ additionalParameters = {} }) => {
      try {
        const response = await api.signing.sign({
          sessionId,
          signatureId,
          signatureProviderType: selectedProvider.split(':')[0],
          additionalParameters: { ...additionalParameters, code: otpCode },
        });
        return response;
      } catch (error: any) {
        try {
          const errorResponse = await error.response.json();
          const respError = errorResponse.error;
          const respErrorCode = errorResponse.errorCode;
          if (respErrorCode === 'SignatureLocked') {
            goToSignatureLockedScreen();
          } else if (respErrorCode) {
            onError({ errorCode: respErrorCode });
          } else if (respError) {
            onError({ message: respError });
          } else {
            onError({});
          }
        } catch (errorParsingError) {
          onError({});
        }
      }
    },
    [goToSignatureLockedScreen, otpCode, selectedProvider, sessionId, signatureId]
  );

  const getSignatureStatus = useCallback(
    async ({ onProviderPending }: { onProviderPending: () => void }) => {
      let data, error;
      try {
        data = await api.signing.getSignatureStatus(sessionId, signatureId);
      } catch (err) {
        error = err;
      }

      const desc = api.signing.describeSignatureFromGetSignatureStatus({ data, error });

      if (desc.success) {
        goToSuccess();
      } else if (desc.providerPending) {
        onProviderPending?.();
      } else if (desc.signatureLocked) {
        goToSignatureLockedScreen();
      } else if (desc.timeout) {
        onTimeout();
      } else if (desc.errorCode !== null) {
        onError({ errorCode: desc.errorCode });
      } else if (desc.failed) {
        onError({});
      }

      return { data, error };
    },
    [goToSignatureLockedScreen, goToSuccess, sessionId, signatureId]
  );

  const providerValues: SigningContextValues = {
    sessionId,
    signatureId,
    file,
    goToSuccess,
    selectedProvider,
    overlay,
    xadesFormat,
    otpCode,
    recipientPhoneNumber,
    goToIntro,
    onCancel,
    setSelectedProvider,
    goToProviderSelection,
    sign,
    getSignatureStatus,
    onError,
    onTimeout,
    setOtpCode,
    showPrivacyPolicy,
  };

  return (
    <SigningContext.Provider value={providerValues}>
      <div className="layered-container">
        {children}
        {signingState.overlay !== null && <div className="layered-container__overlay-background"></div>}
        {signingState.overlay === 'error' && (
          <ErrorScreen {...signingState.errorProps} onCancel={onCancel} onRetry={hideOverlay} />
        )}
        {signingState.overlay === 'timeout' && <TimeoutScreen onCancel={onCancel} onRetry={hideOverlay} />}
        {isPrivacyPolicyVisible && (
          <>
            <div className="background-task"></div>
            <PrivacyPolicy
              onAccept={acceptPrivacyPolicy}
              onClose={closePrivacyPolicy}
              required={!privacyPolicyAcceptance}
            />
          </>
        )}
      </div>
    </SigningContext.Provider>
  );
};

export const useSigningContext = () => {
  const ctx = useContext(SigningContext);
  if (!ctx) {
    throw new Error('Lack of SigningContextProvider in components tree');
  }
  return ctx;
};
