import { useEffect, useState, useMemo, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import * as api from 'shared-fe-components/src/api/index';
import { useFetch } from '../../../lib/useFetch';
import { Intro } from './Intro';
import { EdoFlow, MszafirFlow, IdNowFlow, SimplySignFlow, DummyProvider, NativeFlow } from './';
import { ErrorScreen, SuccessScreen } from 'shared-fe-components/src/common/StatusScreens';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { ProviderSelection } from 'shared-fe-components/src/providers/ProviderSelection';
import { OMIT_URL_LANG_STORAGE_KEY, useLanguage } from '../../../contexts/LanguageContext';
import { SigningContextProvider, useSigningContext } from './SigningContext';
import { usePersistentStorage } from 'shared-fe-components/src/hooks';
import { SigningContextProviderProps } from './SigningContext.types';
import { EidEasyFlow } from './EidEasy/EidEasyFlow';
import { useConfigurationContext } from 'contexts';
import './Signing.scss';

const fetchData = async (sessionId: any, signatureId: any, code?: string) =>
  await api.signing.getSignatureStatus(sessionId, signatureId, code);

const LANG_QUERY_KEY = 'lang';

const supportedLanguagesKeys = ['PL', 'EN', 'DE', 'PT_BR', 'ES'] as const;
type SupportedLanguageKey = (typeof supportedLanguagesKeys)[number]; // All supported language keys recieved as query string params

type SupportedLanguages = {
  [key in SupportedLanguageKey]: string;
};

const isSupportedLanguageKey = (keyInput: string): keyInput is SupportedLanguageKey => {
  return (supportedLanguagesKeys as unknown as string[]).includes(keyInput);
};

const languages: SupportedLanguages = { PL: 'pl', EN: 'en', DE: 'de', ES: 'es', PT_BR: 'pt-br' };

enum SigningScreen {
  Loading,
  Error,
  Success,
  Signing,
}

type SigningScreenViews = {
  [screen in SigningScreen]: ReactNode;
};

export const Signing = () => {
  const { sessionId, signatureId } = useParams();
  const [showIntro, setShowIntro] = useState(true);
  const { pristine, loading, data, error, load, reset } = useFetch(fetchData);
  const { setCanChangeLanguage, setLanguage } = useLanguage();
  const [canRetry, setCanRetry] = useState(true);
  const [errorCode, setErrorCode] = useState(undefined);
  const [initialProvider, setInitialProvider] = useState<string | null>(null);
  const [shouldOmitUrlLang, setShouldOmitUrlLang] = usePersistentStorage(OMIT_URL_LANG_STORAGE_KEY);
  const [otpCode, setOtpCode] = useState<string | null>(null);
  const [signingScreen, setSigningScreen] = useState<SigningScreen>(SigningScreen.Loading);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const langParamValue = params.get(LANG_QUERY_KEY);
    if (langParamValue && isSupportedLanguageKey(langParamValue) && !shouldOmitUrlLang) {
      setLanguage(languages[langParamValue]);
    }
    setShouldOmitUrlLang(false);
  }, []);

  useEffect(() => {
    if (sessionId && signatureId) {
      load(sessionId, signatureId, otpCode);
    } else {
      reset();
    }
  }, [sessionId, signatureId, load, reset, otpCode]);

  useEffect(() => {
    if (data || error) {
      const desc = api.signing.describeSessionFromGetSignatureStatus({ data, error });
      setCanRetry(desc.canRetry);
      setErrorCode(desc.errorCode);
      setInitialProvider(desc.dummyProvider?.replace(/^/, '_dummy:'));
    }
  }, [data, error]);

  const reload = useMemo(() => {
    if (sessionId && signatureId && canRetry) {
      return () => load(sessionId, signatureId);
    }
  }, [canRetry, load, sessionId, signatureId]);

  const goToSuccess = () => setSigningScreen(SigningScreen.Success);

  useEffect(() => {
    if (showIntro) {
      setCanChangeLanguage(true);
    } else {
      setCanChangeLanguage(false);
    }
  }, [setCanChangeLanguage, showIntro]);

  const contextProps = useMemo(
    () => ({
      sessionId,
      signatureId,
      file: data?.document,
      otpCode,
      recipientPhoneNumber: data?.recipient.phoneNumber,
      goToIntro: () => setShowIntro(true),
      goToSuccess,
      initialProvider,
      setOtpCode,
      xadesFormat: data?.xadesFormat,
    }),
    [data?.document, data?.xadesFormat, data?.recipient.phoneNumber, otpCode, initialProvider, sessionId, signatureId]
  );

  const signingScreenViews: SigningScreenViews = {
    [SigningScreen.Loading]: <LoadingScreen />,
    [SigningScreen.Error]: <ErrorScreen errorCode={errorCode} onRetry={reload} />,
    [SigningScreen.Success]: <SuccessScreen signaturesInSession={data?.signaturesInSession} />,
    [SigningScreen.Signing]: (
      <SigningContextProvider {...(contextProps as SigningContextProviderProps)}>
        {showIntro && (
          <Intro
            sender={data?.sender.email}
            file={data?.document}
            protectionType={data?.protectionType}
            otpCode={otpCode}
            onContinue={() => setShowIntro(false)}
          />
        )}
        {!showIntro && <SigningProviderSteps />}
      </SigningContextProvider>
    ),
  } as const;

  useEffect(() => {
    if (pristine || loading) {
      setSigningScreen(SigningScreen.Loading);
    } else if (errorCode) {
      setSigningScreen(SigningScreen.Error);
    } else if (data?.status === 'Succeeded') {
      setSigningScreen(SigningScreen.Success);
    } else if (data) {
      setSigningScreen(SigningScreen.Signing);
    }
  }, [pristine, loading, errorCode, data]);

  return <div className="dashboard-signing">{signingScreenViews[signingScreen]}</div>;
};

const SigningProviderSteps = () => {
  const { selectedProvider, setSelectedProvider, file, goToIntro, xadesFormat } = useSigningContext();
  const { features, signing } = useConfigurationContext();

  return (
    <>
      {!selectedProvider && (
        <ProviderSelection
          {...{ file, features, signing, xadesFormat }}
          onSelection={setSelectedProvider}
          onBack={goToIntro}
          onCancel={goToIntro}
        />
      )}
      {selectedProvider === 'Edo' && <EdoFlow />}
      {selectedProvider === 'Mszafir' && <MszafirFlow />}
      {selectedProvider === 'IdNow' && <IdNowFlow />}
      {selectedProvider === 'SimplySign' && <SimplySignFlow />}
      {selectedProvider === 'Native' && <NativeFlow />}
      {selectedProvider?.startsWith('EidEasy:') && <EidEasyFlow />}
      {selectedProvider?.startsWith('_dummy:') && <DummyProvider />}
      {selectedProvider === 'success' && <SuccessScreen />}
    </>
  );
};
